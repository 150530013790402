<!--
 * @Autor: oops.liu
-->
<template>
  <div class="goods_item" :style="{ width: width }" @click="to(info)">
    <div class="cover_box" :style="{ height: height }">
      <img class="cover" @load="load" :src="info.picUrls[0]" />
    </div>
    <div class="goods_info">
      <div class="goods_name">
        {{ info.name }}
      </div>
      <div class="progress-box">
        <span class="hint">已售{{ info.saleNum }}</span>
        <!-- <van-progress
          :percentage="30"
          color="#FF3B0F"
          track-color="#FFC8B6"
          :show-pivot="false"
          stroke-width="7"
        /> -->
      </div>
      <div class="price_box flex_sb">
        <div class="price">
          <span class="tags">￥</span
          ><span class="now_price">{{ info.priceDown }}</span>
        </div>
        <div class="btns">
          <van-button class="btn">去抢购</van-button>
        </div>
      </div>
      <div class="old-price">
        <del>￥{{ info.marketPrice }}</del>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    width: {
      type: String,
      default: "49%",
    },

    height: {
      type: String,
      default: "172px",
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    to(info) {
      this.$emit("jump", info);
    },
    load() {
      this.$emit("imgload");
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
.goods_item {
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 7px;
  width: 49%;
  .cover_box {
    width: 100%;

    .cover {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .goods_info {
    box-sizing: border-box;
    padding: 7px;

    .goods_name {
      font-size: 12px;
      line-height: 1.2em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-bottom: 5px;
    }
    .progress-box {
      font-size: 12px;
      color: #9c9c9c;
      display: flex;
      align-items: center;
      .hint {
        display: inline-block;
        transform: scale(0.95);
        transform-origin: 0;
      }
      ::v-deep .van-progress {
        display: inline-block;
        width: 66px;
      }
    }
    .price_box {
      padding-top: 15px;

      .price {
        color: #e31802;

        .tags {
          font-size: 12px;
        }

        .now_price {
          font-size: 16px;
          font-weight: bold;
        }
      }
      .btn {
        width: 53px;
        height: 22px;
        background: linear-gradient(90deg, #ff1551 0%, #ff4007 100%);
        border-radius: 5px;
        color: #fff;
        font-size: 12px;
        border: 0px;
      }
    }
    .old-price {
      color: #cccccc;
      font-size: 12px;
    }
  }
}
</style>
