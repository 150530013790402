<!--排序栏
 * @Autor: oops.liu
-->
<template>
  <div class="order_bar flex_sb">
    <div
      class="order_item"
      v-for="(item, index) in list"
      :key="index"
      @click="handle(item)"
      :class="{ active: active === item.id }"
    >
      <div v-if="item.title == '价格'">
        {{ item.title
        }}<i
          :class="{
            order: item.is_order,
            order_asc: order === 1,
            order_desc: order === 0,
          }"
        ></i>
      </div>
      <div v-else>{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  model: {
    prop: "active", //要存在于proops
    event: "change", //当组件的值发生改变时要emit的事件名
  },
  props: {
    active: {
      type: Number,
    },
  },
  data() {
    return {
      order: 0,
      list: [
        {
          id: 1,
          title: "综合",
          is_order: false,
          name: "total",
        },
        {
          id: 2,
          title: "销量",
          is_order: false,
          name: "sale_num",
        },
        {
          id: 3,
          title: "折扣",
          is_order: false,
          name: "discount",
        },

        {
          id: 4,
          title: "价格",
          is_order: true,
          name: "price_down",
        },
      ],

      isOwned: false,
    };
  },

  methods: {
    handle(item) {
      this.order === 1 ? (this.order = 0) : (this.order = 1);
      this.$emit("change", {
        active: item.id,
        sortColumn: item.name,
        asc: this.order === 1,
      });
    },
  },

  mounted() {},
};
</script>

<style scoped lang="scss">
.order_bar {
  width: 100%;
  font-size: 14px;
  background: #fff;
  position: relative;
  .order_item {
    flex: 1;
    padding: 15px 0;
    text-align: center;
    position: relative;
    .order {
      position: absolute;
      height: 16px;
      width: 0px;
      top: 50%;
      transform: translateY(-50%);
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: -3px;
        width: 0;
        height: 0;
        border: 3px solid #333;
        transform: translate(100%);
        border-color: transparent transparent #333 transparent;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: -3px;
        width: 0;
        height: 0;
        border: 3px solid #333;
        transform: translate(100%);
        border-color: #333 transparent transparent transparent;
      }
    }
    ::v-deep .van-dropdown-menu {
      .van-dropdown-menu__bar {
        height: auto;
        box-shadow: none;
      }
    }
    ::v-deep .van-dropdown-item {
      top: 55px !important;
      z-index: 111;
    }
  }
  .owned {
    img {
      width: 12px;
      height: 10px;
    }
  }
  .active {
    color: #f00;
    ::v-deep .van-dropdown-menu {
      .van-dropdown-menu__title {
        color: #f00;
      }
    }
    .order_asc {
      &::before {
        border-color: transparent transparent #ff0000 transparent;
      }
    }
    .order_desc {
      &::after {
        border-color: #ff0000 transparent transparent transparent;
      }
    }
  }
}
</style>
